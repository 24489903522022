@import "../../styles/vars.scss";

.page {
  position: relative;
  height: 100%;
  margin-left: $menu-width;
  padding-top: 1.2rem;

  h1,
  h2,
  h3 {
    text-transform: uppercase;

    &:first-child {
      margin-top: 0;
    }
  }

  &.fullscreen-true {
    position: static;

    #gallery {
      z-index: 2;
    }
  }

  &.type-page {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      display: inline;
      background-color: #fff;
      float: left;
      clear: left;
      padding: 0 0.2em;
      margin: 0;
      margin-bottom: 0.2em;
    }

    .preview p:last-child {
      margin-bottom: 1.2rem;
    }
  }

  &.type-project {
    z-index: 2;
  }

  .logoContainer {
    img {
      display: inline-block;
      max-width: 4em;
      max-height: 4em;
      width: auto;
      height: auto;
      vertical-align: middle;
      margin-right: 1em;
      margin-bottom: 1em;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .page {
    margin-left: 0;

    &.type-page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 2.5em;
    }
  }
}
