@import "../../styles/vars.scss";

#gallery {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-color: #000;
  overflow: hidden;

  .imgWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
    transition: transform 0.5s ease-in-out;

    &.before {
      transform: translateX(-100%);
    }

    &.active {
      transform: translateX(0);
    }

    &.after {
      transform: translateX(100%);
    }

    // &.active + .after {
    //   transform: translateX(66%);
    // }
  }

  .image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .caption {
    display: none;
    position: absolute;
    bottom: 3em;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5em;
    background-color: #fff;
    color: #000;
    font-size: 0.8em;
    font-weight: 500;

    & > * {
      margin: 0;
    }
  }

  nav {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1em;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    div {
      z-index: 2;
      font-size: 3em;
      font-weight: 900;
      color: #fff;
      pointer-events: all;
      cursor: pointer;

      &.expandBtn {
        position: absolute;
        top: 0;
        right: 0;
      }

      &[aria-disabled="true"] {
        opacity: 0;
      }
    }

    // &.inverted {
    //   div {
    //     color: #000;
    //   }
    // }
  }

  &.fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    height: auto;
    left: 0;

    .image {
      background-size: cover;
    }

    .caption {
      display: block;
    }

    nav.inverted {
      div {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #gallery {
    &.fullscreen {
      top: 4.2em;

      .caption {
        font-size: 10px;
      }
    }
  }
}
