@import "./styles/vars.scss";

.App {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  font-family: "Roboto";

  .clickable {
    cursor: pointer;
  }

  h1 {
    font-weight: 900;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
  }

  .flexArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    main {
      flex: 1;
      padding: 0 2em;
      padding-top: $header-gap;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .App {
    .flexArea main {
      padding: 0 1em;
      padding-top: $header-gap-mobile;
    }
  }
}
