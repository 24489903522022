@import "../../styles/vars.scss";

#menuWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 2em;
  padding-top: $header-gap;
  background-color: #fff;
  opacity: 0;
  transform: translateX(-0.5em);
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  text-transform: uppercase;
  font-weight: 500;
  overflow: visible;

  &.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }

  & > ul {
    z-index: 2;
    padding-left: 0;
    width: $menu-width;
  }

  li {
    list-style-type: none;
  }

  h2 {
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0.5rem;
    line-height: 1;
  }

  .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in;

    &.open {
      max-height: 100px;
    }

    li {
      font-size: 0.8em;
      line-height: 1.4;

      &:first-child {
        margin-top: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #menuWrapper {
    z-index: 2;
    padding: 0 1em;
    padding-top: $header-gap-mobile;
  }
}
