@import "../../styles/vars.scss";

header {
  position: fixed;
  top: 0;
  height: $header-gap;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2em;
  color: #fff;
  border-bottom: 3px solid #fff;
  transition: all 0.2s ease-in-out;

  .bna {
    font-size: 4.5em;
    font-weight: 900;
  }

  nav {
    align-self: stretch;
    background-color: #fff;
    color: #000;
    padding: 1em 2em;

    div {
      text-align: center;
      cursor: pointer;

      &.lang {
        width: 1.2em;
        font-size: 1.6em;
      }

      &.menu-btn {
        span {
          display: block;
          height: 0.2em;
          width: 2em;
          background-color: #000;
          margin: 0.3em 0;
          transition: all 0.2s ease-out;
        }

        &.open span:nth-child(2) {
          opacity: 0;
        }

        &.open span:first-child {
          transform-origin: top left;
          transform: translateX(0.4em) translateY(-0.18em) rotate(45deg);
        }

        &.open span:last-child {
          transform-origin: bottom left;
          transform: translateX(0.4em) translateY(0.18em) rotate(-45deg);
        }
      }
    }
  }

  &.inverted:not(.gallery) {
    color: #000;
    border-color: #000;
    background-color: #fff;
  }

  &.gallery nav {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: $mobile-width) {
  header {
    height: calc($header-gap-mobile - 3px);
    padding-left: 1em;
    align-items: flex-start;
    color: #000;
    border-color: #000;
    background-color: #fff;

    .bna {
      font-size: 3em;
      padding: 0.1em 0;
    }

    nav {
      height: 100%;
      padding: 0.65em 1em;

      div {
        display: inline-block;
        vertical-align: middle;
        margin-left: 1em;
      }
    }
  }
}
